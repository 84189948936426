@use "../../styles/Breakpoints.module.scss";

.subscribe-text {
  height: 1px;
  width: 1px;
  overflow: hidden;
  resize: none;
  margin: -1px;
  position: absolute;
}

.buttons-wrapper {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  flex: 1;
}

.buttons-container {
  display: grid;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  row-gap: 1px;
  width: 100%;
  :global(.col) {
    justify-content: flex-end;
    display: flex;
  }
}

.subscribe-button {
  flex: 1;
  min-width: auto;
  width: auto;
  height: 100%;
  align-items: flex-start;
  flex-direction: column;
  max-width: 16rem;
  :global(.button-icon) {
    margin-left: 0;
  }
}

.subscribe-form {
  position: relative;
  margin-top: var(--padding-top);
  input {
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: Breakpoints.$breakpoint-3) {
  .buttons-wrapper {
    width: 100%;
  }
  .buttons-container {
    width: 100%;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 0;
    column-gap: 1px;
  }
  .subscribe-button {
    min-width: 0;
    min-width: auto;
    width: 100%;
    max-width: none;
    height: 8rem;
  }
}
